import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'

const Implementation = () => {
    return (
        <Layout pageTitle='Implementation' nextBottomSlideBtn="Covered Technologies" nextBottomSlideBtnLink="/CoveredTech" id="implementation">
            <div className="fullWidth" id="implementation">
                <div>
                    <p className="textMedSize">With award-winning service and over a century of experience, you can be confident that Southern Company is the right partner to successfully implement your energy projects.</p>
                    <div className="pageContent">
                        <div className="listArea box" style={{ animation: `fadeIn .75s ease-in-out forwards`, opacity: 0 }}>
                            <ul>
                                <li>Multi-technology energy solutions, from installation to ongoing maintenance and optimization</li>
                                <li>Flexible ways to pay</li>
                                <li>White-glove service</li>
                                <li>Technology function guarantee</li>
                            </ul>
                        </div>
                        <div className="box" style={{ animation: `fadeIn .75s ease-in-out forwards 1s`, opacity: 0 }}>
                            <StaticImage
                                alt="this"
                                src="../images/implementation-microgrid1.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Implementation